import { _setFullInfo,_getFullInfo,_setToken, _getToken,_setName,_getName,_setPhone,_getPhone, _getRouterList } from '@/utils/storage'

const app = {
    state: {
        token: '',
        ChineseName:'',
        phone: '',
        FullInfo:0,
        routerList: []
    },
    mutations: {
        //设置用户信息
        setToken(state, payload) {
            state.token = payload
            //localStorage缓存一份
            _setToken(payload)
        },
        //设置用户信息
        setName(state, payload) {
            state.ChineseName = payload
            //localStorage缓存一份
            _setName(payload)
        },
        // 设置手机号
        setPhone(state, payload) {
            state.phone = payload
            //localStorage缓存一份
            _setPhone(payload)
        },
        // 设置货主服务商信息页面填写状态
        setFullInfo(state, payload) {
            state.FullInfo = payload
            //localStorage缓存一份
            _setFullInfo(payload)
        },
        setRouter(state, payload) {
          state.routerList = payload
          //localStorage缓存一份
          _setRouterList(payload)
        },
    },
    getters: {
        //获取货主服务商信息页面填写状态
        getFullInfo(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.FullInfo) {
                state.FullInfo = _getFullInfo()
            }
            return state.FullInfo
        },
        //获取用户信息
        getToken(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.token) {
                state.token = _getToken()
            }
            return state.token
        },
        //获取用户信息
        getName(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.ChineseName) {
                state.ChineseName = _getName()
            }
            return state.ChineseName
        },
        //获取用户手机
        getPhone(state, payload) {
            //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
            if (!state.phone) {
                state.phone = _getPhone()
            }
            return state.phone
        },
        getRouter(state, payload) {
          //先判断vuex内存中有没有数据，如果有就直接取，没有就从localStorage里拿
          if (state.routerList.length === 0) {
              state.routerList = _getRouterList()
          }
          return state.routerList
        }
    }
}

export default app