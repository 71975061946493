//把参数转换成拼接字符串的形式
export const _paramsToQueryString = (params) => {
    return Object.keys(params).map(key => key + "=" + params[key]).join("&")
}

//日期格式化
export const _dateFormat = (params) => {
    var date = new Date(params)
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    month = month > 9 ? month : ('0' + month)
    var day = date.getDate()
    day = day > 9 ? day : ('0' + day)
    var hh = date.getHours()
    hh = hh > 9 ? hh : ('0' + hh)
    var mm = date.getMinutes()
    mm = mm > 9 ? mm : ('0' + mm)
    var ss = date.getSeconds()
    ss = ss > 9 ? ss : ('0' + ss)
    return year + '-' + month + '-' + day + ' ' + hh + ':' + mm + ':' + ss
}

//对象数组根据key值去重
export const _uniqueByKey = (arr, key) => {
    let result = []
    result[0] = arr[0]
    arr.forEach((meta_item, i) => {
        //声明计数变量，如果源数组中的一个对象和result结果数组中的所有对象不同，就push
        let num = 0
        result.forEach((r_item, j) => {
            if (meta_item[key] !== r_item[key]) {
                num++
            }
            if (num === result.length) {
                result.push(meta_item)
            }
        })
    })
    return result
}