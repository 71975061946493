<template>
  <div id="app">
      <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      transitionName: ''
    }
  },
  watch: {
    '$route'(to, from) {
      this.transitionName = 'slide-left'
      this.transitionName = 'slide-right'
    }
  }
}
</script>
<style lang="scss">
.app{
  width: 100%;
  box-sizing: border-box;
}
.topView{
    display: flex;
    justify-content: space-between;
    background: #fff;
    border-radius: 12px;
    padding: 20px 10px 0 10px;
    box-sizing: border-box;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
    min-width: 1130px;
    .searchBox{
      display: flex;
      justify-content: space-between;
    }
    .topbtns{
      margin-left: 30px;
      height: 36px;
      display: flex;
    }
    .headLeft {
      width: 12%;
      display: flex;
      justify-content: space-between;
      vertical-align: top;
      margin-top: 4px;
      img{
        cursor:pointer
      }
    }
    .btns {
      margin:0 0 20px 20px;
    }

  }
  .pageTitle{
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #2081FF;
    margin:16px 0;
    display: flex;
    justify-content: space-between;
    .settleBox{
      width: 780px;
      background: #2081FF;
      box-shadow: 0px 1px 15px rgba(32, 129, 255, 0.26);
      border-radius: 6px;
      padding: 8px 16px;
      box-sizing: border-box;
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #FFFFFF;
      div{
        display: flex;
        justify-content: space-between;
        span{
          font-size: 14px;
          font-weight: bold;
          color: #FFC117;
        }
      }
    }
  }
  .facilityBox{
    background: #fff;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
  }
</style>
<style lang="scss" scoped>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}

.slide-right-leave-active {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translateX(-100%);
}
</style>