import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    component: () => import('@/views/index'),
    redirect:'/platform/deviceManage',
    name: '平台管理',
    children: [
      {
        path: '/platform/deviceManage',
        name:'设备管理',
        redirect: '/platform/deviceManage/list',
        component: () => import('@/views/platform/deviceManage/deviceManage'),
        children: [
          {
            path: '/platform/deviceManage/list',
            name:'设备列表',
            component: () => import('@/views/platform/deviceManage/index')
          },
          {
            path: '/platform/deviceManage/facility',
            name:'设备详情',
            component: () => import('@/views/platform/deviceManage/facility')
          },
          {
            path: '/platform/deviceManage/importDevice',
            name:'导入设备',
            component: () => import('@/views/platform/deviceManage/importDevice')
          },
        ]
      },
      {
        path: '/platform/carManage',
        name:'车辆管理',
        component: () => import('@/views/platform/carManage/carManage'),
        redirect: '/platform/carManage/list',
        children: [
          {
            path: '/platform/carManage/list',
            name:'车辆列表',
            component: () => import('@/views/platform/carManage/index')
          },
          {
            path: '/platform/carManage/importVehicle',
            name:'导入车辆',
            component: () => import('@/views/platform/carManage/importVehicle'),
          },
        ]
      },
      {
        path: '/platform/deviceSkim',
        name:'设备轨迹',
        component: () => import('@/views/platform/deviceSkim/index'),
      },
      {
        path: '/platform/electricFence',
        name:'电子围栏',
        component: () => import('@/views/platform/electricFence/index'),
      },
      {
        path: '/platform/trajectory',
        name:'轨迹列表',
        component: () => import('@/views/platform/trajectory/index'),
      },
      {
        path: '/platform/vehicleMonitoring',
        name:'车辆监控',
        component: () => import('@/views/platform/vehicleMonitoring/index'),
      },
      {
        path: '/platform/trajectoryMate',
        name:'设备匹配',
        component: () => import('@/views/platform/trajectoryMate/index'),
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/views/index'),
    name: '权限管理',
    children: [
      {
        path: '/power/role',
        name: '角色管理',
        component: () => import('@/views/power/role')
      },
      {
        path: '/power/account',
        name: '账号管理',
        component: () => import('@/views/power/account')
      },
      {
        path: '/power/jurisdiction',
        name: '权限分配',
        component: () => import('@/views/power/jurisdiction')
      },
      {
        path: '/power/nopermission',
        name: '暂无权限',
        component: () => import('@/views/power/nopermission'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
