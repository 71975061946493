//存储token
export const _setToken = (token) => {
    localStorage.setItem('token', token)
}
//取出token
export const _getToken = () => {
    return localStorage.getItem('token')
}

//存储用户昵称
export const _setName = (name) => {
    localStorage.setItem('name', name)
}
//取出用户昵称
export const _getName = () => {
    return localStorage.getItem('name')
}

//存储货主服务商信息页面填写状态
export const _setFullInfo = (num) => {
    localStorage.setItem('FullInfo', num)
}
//取出货主服务商信息页面填写状态
export const _getFullInfo = () => {
    return localStorage.getItem('FullInfo')
}

// 存储手机号码
export const _setPhone = (phone) => {
    localStorage.setItem('phone', phone)
}
//取出手机号码
export const _getPhone = () => {
    return localStorage.getItem('phone')
}

//存储角色id
export const _setRoleId = (roleId) => {
    sessionStorage.setItem('roleId', roleId)
}
//取出角色id
export const _getRoleId = () => {
    return sessionStorage.getItem('roleId')
}
//删除角色id
export const _removeRoleId = () => {
    sessionStorage.removeItem('roleId')
}

//存储角色父id
export const _setParentRoleId = (parentRoleId) => {
    sessionStorage.setItem('parentRoleId', parentRoleId)
}
//取出角色父id
export const _getParentRoleId = () => {
    return sessionStorage.getItem('parentRoleId')
}
//删除角色父id
export const _removeParentRoleId = () => {
    sessionStorage.removeItem('parentRoleId')
}

//存储路由列表
export const _setRouterList = (routerList) => {
    localStorage.setItem('routerList', JSON.stringify(routerList))
}
//取出路由列表
export const _getRouterList = (routerList) => {
    return JSON.parse(localStorage.getItem('routerList'))
}
//删除路由列表
export const _removeRouterList = () => {
  localStorage.removeItem('routerList')
}

//存储当前路由地址
export const _setCurrentRouterPath = (currentRouterPath) => {
    sessionStorage.setItem('currentRouterPath', currentRouterPath)
}
//取出当前路由地址
export const _getCurrentRouterPath = () => {
    return sessionStorage.getItem('currentRouterPath')
}
//删除当前路由地址
export const _removeCurrentRouterPath = () => {
    sessionStorage.removeItem('currentRouterPath')
}